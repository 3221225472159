<template>
  <div>
    <div>
      <div class="d-flex align-items-center">
        <div>
          <h1 class="page-title">
            <a-icon type="arrow-left" @click="goBack" />
            {{ $t('productAvailability.adjustment') }}
          </h1>
        </div>
      </div>
    </div>

    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :class="{ info: isDetail }"
    >
      <!-- Detail -->
      <a-card :title="'Detail ' + $t('productAvailability.adjustment')" class="w-100 mt-4" :bordered="false">
        <a-row :gutter="24">
          <a-col :span="10">
            <a-form-model-item :label="$t('sideBar.warehouse')" prop="warehouse_id" class="mb-0">
              <a-select
                v-model="form.warehouse_id"
                size="large"
                class="select-antd-default"
                :placeholder="$t('sideBar.warehouse')"
                disabled
              >
                <a-select-option
                  v-for="item in warehouseList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="10">
            <a-form-model-item :label="$t('productAvailability.adjustmentType')" prop="type">
              <a-select
                v-model="form.type"
                size="large"
                class="select-antd-default"
                :placeholder="$t('productAvailability.adjustmentType')"
                @change="onChangeType"
              >
                <a-select-option
                  v-for="item in adjustmentType"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="10">
            <a-form-model-item :label="$t('productAvailability.adjustmentNumber')" prop="number">
              <a-input
                v-model.trim="form.number"
                size="large"
                autocomplete="off"
                :placeholder="$t('productAvailability.adjustmentNumber')"
                allow-clear
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="10">
            <a-form-model-item :label="$t('productAvailability.grAdjustment')" prop="date">
              <a-date-picker
                v-model="form.date"
                show-time
                size="large"
                class="w-100"
                :format="dateFormat"
                :placeholder="$t('productAvailability.grAdjustment')"
                :disabled-date="disabledDate"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="20">
            <a-form-model-item :label="$t('productAvailability.note')" prop="note" class="mb-0">
              <a-textarea
                v-model.trim="form.note"
                size="large"
                :rows="4"
                autocomplete="off"
                :placeholder="$t('productAvailability.note')"
                allow-clear
              />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-card>

      <!-- Detail Item Produk --> 
      <a-card title="Detail Item Produk" class="w-100 mt-4" :bordered="false">
        <a-row :gutter="24">
          <a-col :span="24">
            <div>
              <div class="d-flex align-items-center justify-content-between mb-3">
                <div class="d-flex">
                  <a-button
                    type="primary"
                    @click="addItem"
                  >
                    Tambah Item
                  </a-button>
                  <a-button
                    class="ml-2"
                    type="primary"
                    @click="showModalBulkItemImport = true"
                  >
                    Bulk Item Import
                  </a-button>
                </div>
                <div>Total SKU: <b>{{ computedValidSKU || 0 }}</b></div>
              </div>
              <a-table
                :columns="columns"
                :row-key="(record, index) => index"
                :data-source="itemList"
                :pagination="false"
                :scroll="{ y: 330 }"
              >
                <template slot="action1" slot-scope="text, record, index">
                  <div
                    @click="onDeleteItem(index)"
                  >
                    <TrashIcon style="color: #E00000; cursor:pointer" />
                  </div>
                </template>
                <template slot="catalog_id" slot-scope="text, record">
                  <a-select
                    v-model="record.catalog_id"
                    size="large"
                    class="select-antd-default"
                    style="width: 100%"
                    placeholder="Produk"
                    show-search
                    :filter-option="false"
                    :not-found-content="fetching ? undefined : null"
                    @change="getProductUOM($event, record)"
                    @blur="handleResetSearch"
                    @search="handleSearch"
                  >
                    <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                    <a-select-option
                      v-for="item in inventories"
                      :key="item.id"
                      :value="`${item.id}.${item.product_id}`"
                      :disabled="selectedCatalogId.includes(item.id)"
                    >
                      <div class="d-flex flex-column product-select">
                        <div>{{ item?.title }}</div>
                        <div class="sku-desc">
                          {{ 'SKU : ' + ellipsisText(item?.sku) }}
                        </div>
                      </div>
                    </a-select-option>
                  </a-select>
                </template>
                <template slot="quantity" slot-scope="text, record">
                  <div class="d-flex">
                    <a-icon
                      v-if="form.type === 'OUT'"
                      type="minus-circle"
                      theme="twoTone"
                      class="mt-2 mr-2"
                      :style="{ fontSize: '20px' }"
                    />
                    <a-icon
                      v-else-if="form.type === 'IN'"
                      type="plus-circle"
                      theme="twoTone"
                      class="mt-2 mr-2"
                      :style="{ fontSize: '20px' }"
                    />
                    <a-input
                      v-model="record.quantity"
                      size="large"
                      autocomplete="off"
                      placeholder="Perubahan Stock"
                      type="number"
                      :min="0"
                      :max="form.type === 'OUT' ? record.onhand : Infinity"
                      allow-clear
                      @change="onChangeQuantity($event.target.value, record)"
                      @blur="onBlurQuantity($event.target.value, record)"
                      @keypress="numberOnly"
                    />
                  </div>
                  <span v-if="!!record.note" style="font-size: 12px; color: red;">{{ record.note }}</span>
                </template>
              </a-table>
            </div>
          </a-col>
        </a-row>
      </a-card>

      <div class="discount-footer">
        <a-button
          type="primary"
          size="large"
          class="mr-4"
          ghost
          :disabled="isLoading"
          @click="goBack"
        >
          {{ $t('utils.cancel') }}
        </a-button>
        <a-button
          type="primary"
          size="large"
          :loading="isLoading"
          @click="onSubmitAdjusment"
        >
          {{ $route.query.discount_id && !isDuplicate ? $t('utils.edit') : $t('utils.save') }}
        </a-button>
      </div>
    </a-form-model>
    <ModalBulkItemImport
      :is-loading="isLoading"
      :visible="showModalBulkItemImport"
      :on-close="() => showModalBulkItemImport = false"
      @handleSubmitModal="onNormalizeData"
    />
    <ModalFeedbackAfterImport
      :revised-data="listRevisedBulkItemImport"
      :is-loading="isLoading"
      :visible="showModalFeedbackAfterImport"
      :on-close="() => showModalFeedbackAfterImport = false"
    />
  </div>
</template>

<script>
import { columnCreateAdjustment } from '@/data/columns'
import { useInputNumber } from '@/composable/useInputNumber'
import { useDateTime } from '@/composable/useDateTime'
import TrashIcon from '@/components/Icons/TrashIcon.vue'
import { getCatalogListGR, getUom } from '@/api/inventory'
import debounce from 'lodash/debounce'
import ModalBulkItemImport from '@/views/inventory/stockAdjustment/create/ModalBulkItemImport.vue'
import ModalFeedbackAfterImport from '@/views/inventory/stockAdjustment/create/ModalFeedbackAfterImport.vue'
import { createAdjustment } from '@/api/inventory'

export default {
  name: 'CreateDiscount',
  components: {
    TrashIcon,
    ModalBulkItemImport,
    ModalFeedbackAfterImport,
  },
  beforeRouteLeave(to, from, next) {
    // if (this.$route.query.discount_id) {
    //   delete this.$route.query.discount_id
    // }
    next()
  },
  setup() {
    const { numberOnly, toNumber } = useInputNumber()
    const { parseISODate } = useDateTime()
    return { numberOnly, toNumber, parseISODate }
  },
  data() {
    return {
      defaultTier: 1,
      readyTier: 1,
      currTier: 1,
      currQuota: 0,
      sellerId: [],
      idPromo: -1,
      dateFormat: 'DD MMM YYYY',
      columns: columnCreateAdjustment,
      isLoading: false,
      isDetail: false,
      fetching: false,
      endOpen: false,
      visible: false,
      areaAll: false,
      distributorAll: false,
      categoryAll: false,
      customerAll: false,
      itemList: [],
      warehouseList: [],
      inventories: [],
      search: '',
      adjustmentType: [
        {
          value: 'IN',
          label: 'Adjust In',
        },
        {
          value: 'OUT',
          label: 'Adjust Out',
        },
      ],
      initVal: {},
      rules: {},
      overlayStyle: {
        width: '100px',
      },
      params: {
        page: 1,
        size: 1000,
      },
      form: {
        note: '',
        number: '',
        type: 'IN',
        date: null,
        warehouse_id: '',
      },
      showModalBulkItemImport: false,
      listRevisedBulkItemImport: [],
      showModalFeedbackAfterImport: false,
    }
  },
  computed: {
    businessId() {
      return this.$store.state.user.restriction_base === 'Warehouse'
        ? Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
        : this.$route.query.business_id
    },
    isPrinciple() {
      return ['business owner', 'supervisor', 'store operator', 'principle'].includes(this.$store.state.user.role_name?.toLowerCase())
    },
    selectedCatalogId() {
      return this.itemList.map((obj2) => obj2.catalog_id.split('.')[0])
    },
    computedValidSKU() {
      return this.itemList.filter((obj) => obj.catalog_id)?.length
    },
  },
  watch: {
    businessId() {
      this.init()
    },
    'form.type'(newValue) {
      this.itemList = this.itemList.map(v => {
        const data = {
          ...v,
          allocated: newValue === 'OUT' ? Number(v.onhand) - Number(v.quantity) : newValue === 'IN' ? Number(v.onhand) + Number(v.quantity) : v.allocated,
        }
        return data
      })
    },
    search: {
      deep: true,
      immediate: true,
      handler: function () {
        this.fetchInventory()
      },
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getWarehouseList()
      this.fetchInventory()
    },
    filterOption(input, option) {
      const itemTitle = option.componentOptions.children[0].text;
      const itemSKU = option.componentOptions.children[2].text;
      const keyword = input.toLowerCase();
        
      return (
        itemTitle.toLowerCase().indexOf(keyword) !== -1 ||
        itemSKU.toLowerCase().indexOf(keyword) !== -1
      )
    },
    disabledDate(current) {
      if (!current) return current && current < this.$moment().startOf('day')
      return (current && current.valueOf() < this.$moment().startOf('day'))
    },
    ellipsisText(text) {
      if(!text) return '-'
      return text.length > 29 ? `${text.substring(0, 26)}...` : text
    },
    onNormalizeData(importedData) {
      this.isLoading = true
      this.listRevisedBulkItemImport = []
      const normalizeData = []
      importedData.map((obj) => {
        const status = []
        const newObj = Object.fromEntries(
          Object.entries(obj).map(([key, val]) => [key.toLowerCase(), val]),
        );
        const isDuplicatedSKU = normalizeData.find((obj2) => obj2.sku === newObj.sku)
        if (isDuplicatedSKU) {
          return
        }
        if (!/^\d+$/.test(newObj.quantity) || !newObj?.sku) {
          status.push('data_not_valid')
        }
        if (status.length) {
          this.listRevisedBulkItemImport.push({
            ...newObj,
            status: status,
          })
          return
        }
        normalizeData.push(newObj)
      })
      this.onSubmitBulkItemImport(normalizeData)
    },
    onSubmitBulkItemImport(importedData) {
      const promises = []
      const tempItemList = this.itemList
      importedData.map((obj) => {
        const isDuplicatedSKU = tempItemList.find((obj2) => obj2.sku === obj.sku)
        if (isDuplicatedSKU) {
          return
        }
        const bodyValue = {
          business_id: this.businessId,
          warehouse_id: this.$route.query.warehouseId,
          search: obj.sku,
          page: 1,
          limit: 20,
        }
        promises.push(
          getCatalogListGR(bodyValue),
        )
      })

      Promise.all(promises)
        .then(async (res) => {
          await Promise.all(res.map(async (obj, index) => {
            const { data, total_row } = obj.data
            if (total_row > 0) {
              const findSpecificCatalog = data.find((item) => item.sku === importedData[index].sku)
              if (findSpecificCatalog) {
                const selecetedCatalog = findSpecificCatalog
                const newRecord = {
                  catalog_id: `${selecetedCatalog.id}.${selecetedCatalog.product_id}`,
                  onhand: selecetedCatalog.availability,
                  quantity: importedData[index].quantity,
                  unit: '',
                  note: '',
                }
                newRecord.allocated = this.form.type === 'OUT'
                  ? +newRecord.onhand - +newRecord.quantity
                  : this.form.type === 'IN'
                  ? +newRecord.onhand + +newRecord.quantity
                  : newRecord.allocated
                
                const { data: { data: uomLevels } } = await getUom({
                  id: selecetedCatalog.product_id,
                  params: {
                    business_id: this.businessId,
                  },
                })
                if (uomLevels) {

                  const findUnit = uomLevels.units.find(unit => unit.numerator == 1 && unit.denumerator == 1)
                  newRecord.unit = findUnit.unit || 'PCS'
                } else {
                  newRecord.unit = 'PCS'
                }
                const isExistOnInventories = this.inventories.find((obj) => obj.id === selecetedCatalog.id)
                if (!isExistOnInventories) {
                  this.inventories.push(selecetedCatalog)
                }
                this.itemList.push(newRecord)
                this.onChangeQuantity(`${newRecord.quantity}`, newRecord)
                this.onBlurQuantity(`${newRecord.quantity}`, newRecord)
              } else {
                this.listRevisedBulkItemImport.push({
                  ...importedData[index],
                  status: ['sku_not_found'],
                })
              }
            } else {
              this.listRevisedBulkItemImport.push({
                ...importedData[index],
                status: ['sku_not_found'],
              })
            }
          }))
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.showModalBulkItemImport = false
          this.isLoading = false
          if (this.listRevisedBulkItemImport.length) {
            this.showModalFeedbackAfterImport = true
            this.$notification.warning({
              message: 'Beberapa data tidak valid',
            })
          } else {
            this.$notification.success({
              message: 'Semua Data Berhasil di Import',
            })
          }
        })
    },
    async onSubmitAdjusment() {
      this.isLoading = true
      const payload = {
        ...this.form,
        business_id: this.businessId,
        type: this.form.type === 'OUT' ? 'ADJOUT' : 'ADJIN',
        adjusted_in: this.form.type === 'IN' ? true : false,
        items: this.itemList.map(v => {
          const data = {
            ...v,
            catalog_id: v.catalog_id.split('.')[0],
            quantity: Number(v.quantity),
            note: '',
          }
          return data
        }),
      }

      await createAdjustment({ data: payload })
      .then(() => {
        this.isLoading = false
        this.$notification.success({
          message: 'Data Berhasil Disimpan',
        })
        this.goBack()
      })
      .catch((err) => {
        console.error({err})
        this.$notification.error({
          message: err?.response?.data?.message || 'Data Gagal Disimpan, Coba Lagi.',
        })
        this.isLoading = false
      })
    },
    async fetchInventory() {
      this.fetching = true
      let bodyValue = {
        business_id: this.businessId,
        warehouse_id: this.$route.query.warehouseId,
        search: this.search,
        page: 1,
        limit: 20,
      }
      
      await getCatalogListGR(bodyValue)
        .then(({ data: { data: response } }) => {
          this.inventories = response
          this.form.warehouse_id = this.$route.query.warehouseId
        })
        .catch(err => {
          this.inventories = []
          console.error(err)
        })
        .finally(() => this.fetching = false)
    },
    handleSearch: debounce(function(e) {
      this.search = e
    }, 500),
    handleResetSearch() {
      this.search = ''
    },
    async getWarehouseList() {
      this.isLoading = true
      await this.$store
        .dispatch('warehouse/GETWAREHOUSELIST', {
          ...this.$route.query,
          business_id: this.businessId,
        })
        .then(response => {
          this.warehouseList = response
          this.form.warehouse_id = this.$route.query.warehouseId
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => this.isLoading = false)
    },
    async getProductUOM(value, record) {
      record.onhand = this.inventories.filter(i => value.split('.')[0] === i.id)?.[0]?.availability || 0
      record.quantity = ''
      record.allocated = this.form.type === 'OUT'
        ? +record.onhand - +record.quantity
        : this.form.type === 'IN'
        ? +record.onhand + +record.quantity
        : record.allocated
      
      await getUom({
        id: value.split('.')[1],
        params: {
          business_id: this.businessId,
        },
      })
      .then(({ data: { data: response } }) => {
        const findUnit = response.units.find(unit => unit.numerator == 1 && unit.denumerator == 1)
        record.unit = findUnit.unit || 'PCS'
      })
      .catch(() => record.unit = 'PCS')
    },
    onChangeQuantity(event, record) {
      const value = this.form.type === 'OUT' && +event > +record.onhand
        ? record.onhand
        : event
      record.allocated = this.form.type === 'OUT'
        ? +record.onhand - +value
        : this.form.type === 'IN'
        ? +record.onhand + +value
        : record.allocated
      record.note = this.form.type === 'OUT' && +event > +record.onhand
        ? 'Tidak boleh melebihi Stok Tersedia'
        : ''
    },
    onBlurQuantity(event, record) {
      if(this.form.type === 'OUT' && +event > +record.onhand) {
        record.quantity = record.onhand
        record.note = ''
      } else {
        if(event.charAt(0) == '0') {
          record.quantity = event.substring(1)
        }
      }
    },
    onChangeType() {
      this.itemList = this.itemList.map(item => {
        item.quantity = ''
        return item
      })
    },
    onChangeItem(key, value, record) {
      if(key === 'catalog_id') {
        const filter = this.itemList.filter(item => {return item.catalog_id && item.catalog_id === value.split('.')[0]})
        if (filter.length > 0) {
          return this.$notification.error({
            message: 'Item sudah ada',
          })
        }
      }
      this.itemList = this.itemList.map(v => {
        const data = {
          ...v,
          [key]: v.catalog_id === record.catalog_id ? value : v[key],
          onhand: v.catalog_id === record.catalog_id && key === 'catalog_id'
            ? this.inventories.filter(i => {
                return value.split('.')[0] === i.id
              })?.[0]?.availability
            : v.onhand,
          allocated: v.catalog_id === record.catalog_id && this.form.type === 'OUT' && key === 'quantity'
            ? Number(v.onhand) - Number(value)
            : v.catalog_id === record.catalog_id && this.form.type === 'IN' && key === 'quantity'
            ? Number(v.onhand) + Number(value)
            : v.allocated,
        }
        return data
      })
    },
    onDeleteItem(index) {
      this.itemList.splice(index, 1)
    },
    addItem() {
     const arr = this.itemList
     const temp = {
        catalog_id: '',
        onhand: '',
        allocated: '',
        quantity: '',
        unit: '',
        note: '',
     }
     arr.push(temp)
     this.itemList = arr
    },
    goBack() {
      this.$router.back({
        path: '/inventory/stock-adjustment',
        query: { ...this.$route.query },
      })
    },
  },
}
</script>

<style lang="scss">
@import './create.module.scss';
</style>
<style lang="scss">
.ant-select-lg .ant-select-selection--single .product-select {
  white-space: nowrap !important;
  max-width: 350px;
}
.product-select {
  white-space: pre-wrap !important;
  .sku-desc {
    font-size: 12px;
    font-weight: 500;
  }
}
</style>
